import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { RichText } from '@/components/rich-text';

import parse from 'html-react-parser';
import classNames from 'classnames';

// Acts as the standard default popup template for text based popups.

export const PopupBody = ({ popup_style, ...props }) => {
    const basic = popup_style === 'basic' || popup_style === 'warning';
    const multi = popup_style === 'multi';
    return (
        <>
            {basic && <PopupBasic popup_style={popup_style} {...props} />}{' '}
            {multi && <PopupMulti {...props} />}
        </>
    );
};

function PopupBasic({
    title,
    description,
    decline,
    handleDecline,
    accept,
    handleAccept,
    accept_url,
    popup_style,
    icon,
    icon_position,
    button_size,
}) {
    return (
        <div
            className={classNames('popup', {
                [`popup--${popup_style}`]: popup_style,
            })}
        >
            {title?.value && (
                <div className="popup__head">
                    <Icon
                        className="popup__icon"
                        wrapperIconClassName="popup__icon-wrapper"
                        icon={'fa-solid fa-triangle-exclamation'}
                    />
                    <div className="popup__title">
                        <RichText data={title} />
                    </div>
                </div>
            )}
            {description && (
                <div className="popup__description">{parse(description)}</div>
            )}
            <div className="popup__links">
                {decline && (
                    <Button
                        className="popup__link"
                        trigger={handleDecline}
                        btn_style="ghost"
                    >
                        {decline}
                    </Button>
                )}
                {accept && (
                    <Button
                        className="popup__link"
                        trigger={handleAccept}
                        cta_link={accept_url}
                        target
                        icon={icon}
                        icon_position={icon_position}
                        btn_size={button_size}
                    >
                        {accept}
                    </Button>
                )}
            </div>
        </div>
    );
}

function PopupMulti({
    title,
    description,
    accept,
    handleAccept,
    accept_url,
    locale,
    icon,
    icon_position,
    button_size,
    links,
}) {
    return (
        <div className={'popup popup--multi'}>
            {title?.value && (
                <div className="popup__head">
                    <div className="popup__title">
                        <RichText data={title} />
                    </div>
                </div>
            )}
            {description && (
                <div className="popup__description">{parse(description)}</div>
            )}
            {accept && (
                <Button
                    trigger={handleAccept}
                    cta_link={accept_url}
                    target
                    icon={icon}
                    icon_position={icon_position}
                    btn_size={button_size}
                >
                    {accept}
                </Button>
            )}
            {links && (
                <>
                    <hr className="popup__hr" />
                    <div className="popup__links">
                        {links?.map((link) => (
                            <a
                                className="popup__link"
                                href={link.url}
                                target="_blank"
                            >
                                <div>{parse(link.description)}</div>
                                <Icon
                                    className="popup__icon"
                                    wrapperIconClassName="popup__icon-wrapper"
                                    icon={
                                        'fa-regular fa-arrow-up-right-from-square'
                                    }
                                />
                            </a>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
