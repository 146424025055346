'use client';

import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { Caption } from '@/components/caption';
import { Icon } from '@/components/icon';
import { Image } from '@/components/media/elements/image';
const Skeleton = dynamic(() => import('react-loading-skeleton'));
import { useModal } from '@/lib/hooks/use-modal';
import { Modal } from '@/components/modal';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });
import { useState, useEffect } from 'react';
import { VideoModalContent } from '@/components/media/elements/video-modal-content';

export const Video = ({
    video,
    className,
    disableModal,
    disableCaption,
    disableElevation,
    slideChange,
}) => {
    const { isOpen, setIsOpen } = useModal();
    const [playing, setPlaying] = useState(false);
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setPlaying(false);
    }, [slideChange]);
    return (
        <>
            {/* Regular Inline Videos */}
            {((!video?.thumbnail &&
                !video?.button?.cta_link?.includes('.mp4')) ||
                disableModal) && (
                <div className="video" data-kontent-item-id={video.id}>
                    <ReactPlayer
                        className={classNames('video__frame', {
                            [`${className}`]: className,
                        })}
                        url={video?.button?.cta_link}
                        onPlay={() => setPlaying(true)}
                        onReady={() => {
                            if (video.autoplay) {
                                setPlaying(true);
                            }
                            setReady(true);
                        }}
                        config={{
                            wistia: {
                                options: {
                                    playerColor: video.player_color,
                                },
                            },
                        }}
                        playing={playing}
                        muted={video?.autoplay}
                        controls={!video?.disable_controls}
                        data-kontent-element-codename={video.codename}
                    />
                    {!ready && (
                        <Skeleton className="video__skeleton" height={1080} />
                    )}
                </div>
            )}
            {video?.caption && !disableCaption && !video?.thumbnail && (
                <Caption
                    caption={video?.caption}
                    alignment={video?.caption_alignment}
                />
            )}

            {/* Modal Videos */}
            {video?.thumbnail &&
                !disableModal &&
                !video?.button?.cta_link?.includes('.mp4') && (
                    <>
                        <button
                            onClick={() => setIsOpen(true)}
                            className={classNames('modal__video', {
                                [`${className}`]: className,
                            })}
                        >
                            <Image
                                image={video.thumbnail}
                                fill
                                objectFit={'cover'}
                                objectPosition="center"
                                className={className}
                                wrapperClassName={'video__image'}
                                disableElevation={disableElevation}
                                disableCaption={true}
                            />
                            <Icon
                                icon={'fa-duotone fa-circle-play'}
                                className="modal__icon"
                            />
                        </button>
                        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                            <VideoModalContent
                                video={video}
                                setIsOpen={setIsOpen}
                                disableModal
                            />
                        </Modal>
                        {video?.caption && !disableCaption && (
                            <Caption
                                caption={video?.caption}
                                alignment={video?.caption_alignment}
                            />
                        )}
                    </>
                )}
        </>
    );
};
