import { Video } from './elements/video';
import { Icon } from '@/components/icon';
import { ComparisonSlider } from '@/components/comparison-slider';
import { InternalVideo } from './elements/internal-video';
import { Iframe } from './elements/iframe';
import { MediaLink } from './elements/media-link';
import { Image } from '@/components/media/elements/image';

export const Media = ({
    className,
    wrapperClassName,
    wrapperIconClassName,
    media,
    fill,
    objectFit,
    objectPosition,
    priority,
    defaultBackground,
    disableModal,
    disableElevation,
    disableCaption,
    icon_size,
    slideChange,
    sizes,
}) => {
    if (!media) {
        return null;
    }
    const { type, id } = media;
    return (
        <>
            {type === 'image' && (
                <Image
                    image={media}
                    className={className}
                    wrapperClassName={wrapperClassName}
                    fill={fill}
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    priority={priority}
                    defaultBackground={defaultBackground}
                    disableElevation={disableElevation}
                    disableCaption={disableCaption}
                    sizes={sizes}
                />
            )}
            {type === 'internal_video' && (
                <InternalVideo
                    key={id}
                    video={media}
                    className={className}
                    wrapperClassName={wrapperClassName}
                />
            )}
            {type === 'video' && (
                <Video
                    video={media}
                    className={className}
                    disableModal={disableModal}
                    disableElevation={disableElevation}
                    disableCaption={disableCaption}
                    slideChange={slideChange}
                />
            )}
            {type === 'icon' && (
                <Icon
                    wrapperIconClassName={wrapperIconClassName}
                    className={className}
                    icon={media.icon}
                    size={icon_size}
                    color={media.color}
                    primary={media.primary}
                    secondary={media.secondary}
                />
            )}
            {(type === 'image_link' || type === 'pdf') && (
                <MediaLink media={media} />
            )}
            {type === 'comparison_slider' && (
                <ComparisonSlider media={media} sizes={sizes} />
            )}
            {type === 'iframe' && <Iframe media={media} />}
        </>
    );
};
