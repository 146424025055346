'use client';

import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { Linker } from '@/components/linker';
import classNames from 'classnames';

export const Button = ({
    id,
    codename,
    element_type = 'button',
    btn_style,
    btn_size,
    icon_position,
    disabled,
    children,
    block,
    className,
    type,
    trigger,
    cta_link,
    icon,
    target,
    intrinsic,
    disable_icon = false,
}) => {
    const ElementType = element_type === 'button' ? 'button' : element_type;
    return (
        <>
            {cta_link && btn_style !== 'linker' && (
                <Link
                    href={cta_link}
                    className={classNames('btn', {
                        [`${className}`]: className,
                        'btn-primary':
                            btn_style === null || btn_style === undefined,
                        [`btn-${btn_style}`]: btn_style,
                        [`btn-${btn_size}`]: btn_size && btn_size !== 'md',
                        'btn-block': block,
                        'btn--disabled': disabled,
                        'btn--intrinsic': intrinsic,
                    })}
                    disabled={disabled}
                    type={type}
                    onClick={trigger}
                    id={id}
                    codename={codename}
                    target={target}
                >
                    {icon_position === 'left' && icon && (
                        <Icon
                            key={Date.now()}
                            icon={icon}
                            className={classNames({ 'mr-2': children })}
                        />
                    )}
                    {children}
                    {icon_position === 'right' && icon && (
                        <Icon
                            key={Date.now()}
                            icon={icon}
                            className={classNames({ 'ml-2': children })}
                        />
                    )}
                </Link>
            )}

            {btn_style === 'linker' && (
                <Linker
                    className={className}
                    id={id}
                    codename={codename}
                    cta_link={cta_link}
                    btn_size={btn_size}
                    element_type={element_type}
                    target={target}
                    disable_icon={disable_icon}
                >
                    {children}
                </Linker>
            )}

            {!cta_link && btn_style !== 'linker' && (
                <ElementType
                    className={classNames('btn', {
                        [`${className}`]: className,
                        'btn-primary':
                            btn_style === null || btn_style === undefined,
                        [`btn-${btn_style}`]: btn_style,
                        [`btn-${btn_size}`]: btn_size && btn_size !== 'md',
                        'btn-block': block,
                        'btn--disabled': disabled,
                    })}
                    disabled={disabled}
                    type={type}
                    onClick={trigger}
                    data-kontent-item-id={id}
                    data-kontent-element-codename={codename}
                >
                    {icon_position === 'left' && icon && (
                        <Icon
                            icon={icon}
                            className={classNames({ 'mr-2': children })}
                        />
                    )}
                    {children}
                    {icon_position === 'right' && icon && (
                        <Icon
                            icon={icon}
                            className={classNames({ 'ml-2': children })}
                        />
                    )}
                </ElementType>
            )}
        </>
    );
};
