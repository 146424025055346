import classNames from 'classnames';
import parse from 'html-react-parser';

export const Testimonial = ({ quote, cite, tag, disable_quotes }) => {
    return (
        <blockquote
            className={classNames('testimonial', {
                'testimonial--disable-quotes': disable_quotes,
            })}
        >
            {quote && (
                <div
                    className={classNames('testimonial__quote', {
                        [`testimonial__quote--${tag}`]: tag,
                    })}
                >
                    {parse(quote)}
                </div>
            )}
            {cite && (
                <div className="testimonial__cite-wrapper">
                    <span className="testimonial__dash">-</span>{' '}
                    <cite className="testimonial__cite">{parse(cite)}</cite>
                </div>
            )}
        </blockquote>
    );
};
