'use client';

import NextLink from 'next/link';
import { getUrl } from '@/lib/utils/get-url';
import { useCurrentLocale } from 'next-i18n-router/client';
import { i18nConfig } from '@/lib/i18n-config';
import { usePathname, useRouter } from 'next/navigation';

export const Link = ({
    children,
    href,
    className,
    onClick,
    disabled,
    type,
    target,
    tabIndex,
    id,
    codename,
    add_button,
    add_button_render_position,
    add_button_insert_position,
}) => {
    const lang = useCurrentLocale(i18nConfig);

    const basePath = lang === 'default' ? '' : `/${lang}`;
    const router = useRouter();
    const pathname = usePathname();

    if (!href || !children) {
        return null;
    }

    const isExternalLink = href.startsWith('http') || href.startsWith('https');
    const isQueryParamLink = href.startsWith('?');

    const linkProps = {
        className,
        onClick,
        disabled,
        type,
        target: target ? '_blank' : '_self',
        tabIndex,
        'data-kontent-item-id': id,
        'data-kontent-element-codename': codename,
        'data-kontent-add-button': add_button,
        'data-kontent-add-button-render-position': add_button_render_position,
        'data-kontent-add-button-insert-position': add_button_insert_position,
    };

    if (isExternalLink) {
        return (
            <a href={getUrl(href)} {...linkProps}>
                {children}
            </a>
        );
    }

    if (isQueryParamLink) {
        return (
            <a
                href={getUrl(href)}
                {...linkProps}
                onClick={(e) => {
                    e.preventDefault();
                    router.replace(`${pathname}${href}`, {
                        shallow: true,
                        scroll: false,
                    });
                }}
            >
                {children}
            </a>
        );
    }

    return (
        <NextLink href={`${basePath}${getUrl(href)}`} {...linkProps}>
            {children}
        </NextLink>
    );
};
