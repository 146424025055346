export const Iframe = ({ media }) => {
    return (
        <div className="video">
            <iframe
                className={'video__frame'}
                width="560"
                height="315"
                src={media?.url}
                loading="lazy"
                title="Video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};
