import { Button } from '@/components/button';
import { RichText } from '@/components/rich-text';
import { Link } from '@/components/link';
import { Media } from '@/components/media';

export const MediaLink = ({ media }) => {
    return (
        <div>
            {media?.title?.value && (
                <RichText className={'mb-4'} data={media.title} />
            )}
            {media?.button?.cta_link && (
                <Link href={media.button.cta_link} target={media.button.target}>
                    {media.media && <Media media={media.media} />}
                    {media.button && (
                        <Button
                            className={'mt-6'}
                            btn_size={media.button.btn_size}
                            btn_style={media.button.btn_style}
                            icon={media.button.icon}
                            icon_position={media.button.icon_position}
                            element_type="div"
                            block
                        >
                            {media.button?.cta_text}
                        </Button>
                    )}
                </Link>
            )}
        </div>
    );
};
