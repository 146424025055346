'use client';

import { Image } from '@/components/media/elements/image';
import { Caption } from '@/components/caption';
import { Handle } from '@/components/comparison-slider/elements/handle';
import { ReactCompareSlider } from 'react-compare-slider';
import classNames from 'classnames';

export const ComparisonSlider = ({ media, sizes }) => {
    return (
        <>
            <ReactCompareSlider
                boundsPadding={40}
                className={classNames('comparison', {
                    [`elevation-${media.elevation}`]: media.elevation,
                })}
                handle={<Handle />}
                itemOne={
                    <Image
                        wrapperClassName="comparison__image"
                        sizes={sizes}
                        image={media.images[0]}
                    />
                }
                itemTwo={
                    <Image
                        wrapperClassName="comparison__image"
                        sizes={sizes}
                        image={media.images[1]}
                    />
                }
                position={media.position}
            />
            {media.caption && <Caption caption={media.caption} />}
        </>
    );
};
