import { getUrl } from '@/lib/utils/get-url';
import { List } from '@/components/list';
import { Text } from '@/components/text';
import { Metric } from '@/components/metric';
import { Testimonial } from '@/components/testimonial';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { Link } from '@/components/link';

export const RichText = ({ className, data }) => {
    function replace(domNode, index) {
        // transform anchor tag with next/link for internal links starting with '/'
        if (
            domNode.type === 'tag' &&
            domNode.name === 'a' &&
            domNode.attribs.href?.startsWith('/')
        ) {
            return (
                <Link key={index} href={getUrl(domNode.attribs.href)}>
                    {domNode.children[0]?.data
                        ? domNode.children[0]?.data
                        : domNode.children[0]?.children[0]
                        ? domNode.children[0]?.children[0]?.data
                        : domNode.attribs.href}
                </Link>
            );
        }
        //Components;
        if (domNode.type === 'tag' && domNode.name === 'object') {
            const codename = domNode.attribs['data-codename'];
            const component = data?.components?.find(
                (component) => component?.codename === codename
            );
            const type = component?.type;
            if (!type) {
                return null;
            }
            switch (type) {
                case 'list':
                    return <List key={index} {...component} />;
                case 'text':
                    return <Text key={index} {...component} />;
                case 'testimonial':
                    return <Testimonial key={index} {...component} />;
                case 'metric':
                    return <Metric key={index} {...component} />;
                default:
                    return null;
            }
        }

        //Linking URLs of components dynamically
        if (
            domNode.type === 'tag' &&
            domNode.name === 'a' &&
            domNode.attribs['data-item-id']
        ) {
            data?.links?.forEach((url) => {
                if (url?.linkId === domNode.attribs['data-item-id']) {
                    domNode.attribs.href = url.urlSlug;
                }
            });

            return (
                <Link key={index} href={getUrl(domNode.attribs.href)}>
                    {domNode.children[0]?.data
                        ? domNode.children[0]?.data
                        : domNode.children[0]?.children[0]
                        ? domNode.children[0]?.children[0]?.data
                        : domNode.attribs.href}
                </Link>
            );
        }
    }

    function transform(reactNode, domNode, index) {
        // table should be wrapped with extra div to control overflow
        if (domNode.type === 'tag' && domNode.name === 'table') {
            return (
                <div className="richtext__table" key={index}>
                    <table
                        border={domNode.attribs.border}
                        cellPadding={domNode.attribs.cellpadding}
                        cellSpacing={domNode.attribs.cellspacing}
                        className={domNode.attribs.class}
                    >
                        {reactNode.props.children}
                    </table>
                </div>
            );
        }
        // image formatting changes
        if (domNode.type === 'tag' && domNode.name === 'img') {
            return (
                <div key={index} className="richtext__image">
                    {reactNode}
                </div>
            );
        }

        return reactNode;
    }

    const options = {
        replace,
        transform,
    };

    if (typeof data.value !== 'string') {
        return null;
    }

    return (
        <div
            className={classNames('richtext', { [`${className}`]: className })}
        >
            {parse(data.value, options)}
        </div>
    );
};
