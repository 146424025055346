import classNames from 'classnames';
import parse from 'html-react-parser';

export const Caption = ({ caption, alignment, size }) => {
    return (
        <div
            className={classNames('caption', {
                'caption--left': alignment === 'left',
                'caption--lg': size === 'large',
            })}
        >
            {caption && parse(caption)}
        </div>
    );
};
